import { Box, Typography } from '@mui/material';
import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      NAVI Offline {new Date().getFullYear()}.
    </Typography>
  </Box>
);

export default Footer;
