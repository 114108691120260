import { ICustodianBank, defaultValue as defaultValueCustodianBank } from "./custodian-bank.model";
import { IInvestmentManager, defaultValue as defaultValueInvestmentManager } from "./investment-manager.model";
import { IMutualFundNAV, defaultValue as defaultValueMutualFundNAV } from "./mutual-fund-nav.model";
import { IMutualFundReturn, defaultValue as defaultValueMutualFundReturn } from "./mutual-fund-return.model";
import { IMutualFund, defaultValue as defaultValueMutualFund } from "./mutual-fund.model";

export interface IProduct {
  custodianBankEntity?: ICustodianBank;
  id?: number;
  investmentManagerEntity?: IInvestmentManager;
  mutualFundEntity?: IMutualFund;
  mutualFundNAVEntity?: IMutualFundNAV;
  mutualFundNAVPrevEntity?: IMutualFundNAV;
  mutualFundReturnEntity?: IMutualFundReturn;
  mutualfundreturn?: number;
  name?: string;
  totalManagedFund?: number;
}

export const defaultValue: Readonly<IProduct> = {
  custodianBankEntity: defaultValueCustodianBank,
  id: 0,
  investmentManagerEntity: defaultValueInvestmentManager,
  mutualFundEntity: defaultValueMutualFund,
  mutualFundNAVEntity: defaultValueMutualFundNAV,
  mutualFundNAVPrevEntity: defaultValueMutualFundNAV,
  mutualFundReturnEntity: defaultValueMutualFundReturn,
  mutualfundreturn: 0,
  name: '',
  totalManagedFund: 0
};

export interface IProductTable {
  page: number;
  size: number;
  sort: string;
}

export const defaultValueSearchProducts: Readonly<IProductTable> = {
  page: 0,
  size: 20,
  sort: 'totalManagedFund,desc',
};
