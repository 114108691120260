import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, CircularProgress, IconButton, InputBase, Paper, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { IoSearchSharp } from "react-icons/io5";
import { JhiItemCount, JhiPagination, Translate, getPaginationState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Table } from 'reactstrap';
import { getRMs } from './rm.reducer';

export const RM = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'rmNik'), pageLocation.search),
  );

  const getRMsFromProps = () => {
    dispatch(
      getRMs({
        page: pagination.activePage - 1,
        size: pagination.itemsPerPage,
        sort: `${pagination.sort},${pagination.order}`,
      }),
    );
    const endURL = `?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getRMsFromProps();
  }, [pagination.activePage, pagination.order, pagination.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sortParam = params.get(SORT);
    if (page && sortParam) {
      const sortSplit = sortParam.split(',');
      setPagination({
        ...pagination,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () =>
    setPagination({
      ...pagination,
      order: pagination.order === ASC ? DESC : ASC,
      sort: p,
    });

  const handlePagination = currentPage =>
    setPagination({
      ...pagination,
      activePage: currentPage,
    });


  const rMs = useAppSelector(state => state.rm.rMs);
  const totalItems = useAppSelector(state => state.rm.totalItems);
  const loading = useAppSelector(state => state.rm.loading);
  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = pagination.sort;
    const order = pagination.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="rm-page-heading" data-cy="rmPageHeading" className='pb-2'>
        <Translate contentKey="rm.home.title">Relationship Manager</Translate>
      </h2>
      {/* <Paper
        component="form"
        elevation={2}
        sx={{ p: '2px 4px', mb: '8px', display: 'flex', alignItems: 'center', minWidth: 400, maxWidth: 600 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={translate('rm.home.search')}
          inputProps={{ 'aria-label': translate('rm.home.search') }}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <IoSearchSharp />
        </IconButton>
      </Paper> */}
      <Card>
        {/* <TextField fullWidth label={translate('rm.home.search')} variant="outlined" /> */}
        {/* <Divider className='py-4' /> */}
        <CardContent>
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>
                  <Typography variant="subtitle1" color="text.primary" align="left">
                    {translate('rm.table.name')}
                  </Typography>
                </th>
                <th>
                  <Typography variant="subtitle1" color="text.primary" align="left">
                    {translate('rm.table.nik')}
                  </Typography>
                </th>
                <th>
                  <Typography variant="subtitle1" color="text.primary" align="left">
                    {translate('rm.table.email')}
                  </Typography>
                </th>
                <th>
                  <Typography variant="subtitle1" color="text.primary" align="left">
                    {translate('rm.table.status')}
                  </Typography>
                </th>
                <th>
                  <Typography variant="subtitle1" color="text.primary" align="right">
                  {translate('customer.table.action')}
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} align='center'>
                    <CircularProgress color="inherit" />
                  </td>
                </tr>
              ) : rMs?.length > 0 ? rMs.map((item, i) => (
                <tr id={item.rmEmail} key={`item-${i}`}>
                  <td>{item.rmName}</td>
                  <td>{item.rmNik}</td>
                  <td>{item.rmEmail}</td>
                  <td>
                    <div key={`status-item-${i}`}>
                      {item.rmStatus === '0' ? (
                        <Badge color="success">Active</Badge>
                      ) : (
                        <Badge color="info">Non Active</Badge>
                      )}
                    </div>
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/customer/rm/${item.rmNik}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="rm.table.viewCustomer">View Customer</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={5} align='center' className='py-4'>
                    <Typography variant="subtitle1" color="text.primary" align="center">
                      {translate('rm.table.empty')}
                    </Typography>
                  </td>
                </tr>)}
            </tbody>
          </Table>
          {totalItems ? (
            <div className={rMs?.length > 0 ? '' : 'd-none'}>
              <div className="justify-content-center d-flex">
                <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} i18nEnabled />
              </div>
              <div className="justify-content-center d-flex">
                <JhiPagination
                  activePage={pagination.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={pagination.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default RM;
