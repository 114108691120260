import React, { useEffect, useLayoutEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router';

export const Logout = () => {
  const authentication = useAppSelector(state => state.authentication);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // useLayoutEffect(() => {
  //   dispatch(logout());
  //   if (authentication.logoutUrl) {
  //     window.location.href = authentication.logoutUrl;
  //   } else if (!authentication.isAuthenticated) {
  //     window.location.href = '/';
  //   }
  // });

  useEffect(() => {
    if (!authentication.isAuthenticated) {
      // window.location.href = '/';
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <div className="p-5">
      <h4>Logged out successfully!</h4>
    </div>
  );
};

export default Logout;
