
export interface IAccountBalance {
  code?: string;
  message?: string;
  data?: IAccountBalanceData;
}

export const defaultValue: Readonly<IAccountBalance> = {
  code: '',
  message: '',
  data: null
};

export interface IAccountBalanceData {
  bank_code?: string;
  acnt_type?: string;
  acnt_no?: string;
  cif?: string;
  user_id?: string;
  rdn_acc_no?: string;
  last_tr_seq?: number;
  branch_code?: string;
  acnt_name?: string;
  branch_name?: string;
  bank_name?: string;
  request_date?: Date | null;
  currency?: string;
  cash_ont0?: number;
  cash_ont2?: number;
  rdn_balance?: number;
}
