import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import { Alert, Backdrop, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { LoadingButton } from '@mui/lab';
import { ScaleLoader } from 'react-spinners';

export const Login = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const errorMessage = useAppSelector(state => state.authentication.errorMessage);
  const isLoading = useAppSelector(state => state.authentication.loading);
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const [showModal, setShowModal] = useState(showModalLogin);
  const navigate = useNavigate();
  const pageLocation = useLocation();

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleLogin = () => {
    if(username.length < 1) {
      toast.error(translate('login.error.emptynik'));
    } else if((username.length > 0 && username.length < 7) || isNaN(+username)){
      toast.error(translate('login.error.invalidnik'));
    } else if(password.length < 1) {
      toast.error(translate('login.error.emptypassword'));
    } else {
      // props.login(username, password, true);
      dispatch(login(username, password, true));
    }
  };
  
  const onEnter = (e) => {
    if(username.length >= 7 && password.length >= 1 && !isNaN(+username)){
      if (e.key === "Enter") {
        // props.login(username, password, true)
        dispatch(login(username, password, true));
        // const handleLogin = (username, password, rememberMe = false) => dispatch(login(username, password, rememberMe));
      }
    }
  };

  // const { from } = pageLocation.state || { from: { pathname: '/', search: pageLocation.search } };
  if (isAuthenticated) {
    return <Navigate to={'/home'} replace />;
  }

  const styles = {
    bgTransparant: {
      // backgroundColor:'rgba(255, 255, 255, 0.7)',
      width:'30vw'
    },
    styleLogin: {
      backgroundImage: `url('content/images/background-login.png')`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh'
    },
    styles: {
      paddingTop: '20px'
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        {/* <CircularProgress color="inherit" /> */}
        <ScaleLoader color="#f4801b" />
      </Backdrop>
      <div style={styles.styleLogin} className="d-flex align-items-center justify-content-center">
        <Paper elevation={24} style={styles.bgTransparant}>
          <div className="my-4 mx-4">
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <img src="https://navi.miraeasset.co.id/img/logo.png" width="100"></img>
                  <Typography variant="h6" sx={{ mt:1 }}>Sign in to Navi Offline</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <TextField name="username" label="NIK"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // size='small'
                    fullWidth
                    required
                    autoFocus
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setUsername(event.target.value);
                    }}
                    error={(username.length > 3 && username.length < 7) || isNaN(+username)}
                    helperText={(username.length > 3 && username.length < 7) || isNaN(+username) ? translate('login.error.invalidnik') : ""}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <TextField name="password" label="Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // size='small'
                    fullWidth
                    required
                    type={showPassword ? 'text' : 'password'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(event.target.value);
                    }}
                    onKeyDown = {e => onEnter(e)} 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    disabled={username.length < 7 || password.length < 1 || isNaN(+username)}
                    loading={isLoading}
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                  >
                    Login
                  </LoadingButton>
                </Grid>
                {loginError ? (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">
                      {/* {translate('login.error.unauthorized')} */}
                      {errorMessage}
                    </Alert>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default Login;
