import { IBankAccount } from './bank-account.model';
import { IClientGerai } from './client-gerai.model';
import { IClientUser } from './client-user.model';
import { IClient, defaultValue as defaultValueClient } from './client.model';
import { IIndividu, defaultValue as defaultValueIndividu } from './individu.model';
import { IInstitution, defaultValue as defaultValueInstitution } from './institution.model';

export interface IInvestor {
  type?: string;
  bankAccountEntities?: IBankAccount[];
  clientEntity?: IClient;
  clientGeraiEntities?: IClientGerai[];
  clientUserEntities?: IClientUser[];
  individuEntity?: IIndividu;
  institutionEntity?: IInstitution;
//   officerEntities?: IOfficer[];
//   clientApprovalHistoryEntities?: IClientApprovalHistory[];
//   geraiEntities?: IGerai[];
//   rejectMessage?: string;
//   clientAMLEntites?: IClientAML[];
//   occupationEntity?: IOccupation;
}

export const defaultValue: Readonly<IInvestor> = {
  type: 'INDIVIDU',
  bankAccountEntities: [{}],
  clientEntity: defaultValueClient,
  clientGeraiEntities: [{}],
  clientUserEntities: [{}],
  individuEntity: defaultValueIndividu,
  institutionEntity: defaultValueInstitution

//   officerEntities: [{}],
//   clientApprovalHistoryEntities: [{}],
//   geraiEntities: [{}],
//   rejectMessage: '',
//   clientAMLEntites: [{}],
//   occupationEntity: defaultValueOccupation
};