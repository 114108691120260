export interface ICustodianBank {
  address?: string;
  address2?: string;
  authorizedSignatories?: string;
  code?: string;
  country?: number;
  createdAt?: Date | null;
  fax?: string;
  id?: number;
  logoPath?: string;
  name?: string;
  phone?: string;
  phone2?: string;
  postalCode?: string;
  province?: number;
  regency?: number;
  swiftCode?: string;
  updatedAt?: Date | null;
}

export const defaultValue: Readonly<ICustodianBank> = {};
