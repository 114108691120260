import * as React from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';

import ListMenu from './list-menu';

export const Navigator = (state: DrawerProps) => {
  const { ...other } = state;
  
  return (
    <Drawer variant="permanent" {...other}>
      <ListMenu />
    </Drawer>
  );
};

export default Navigator;