import { Currency, OrderStatus } from './enum.model';
import { IOrderItem } from './order-item.model';

export interface ITrxOrder {
  id?: number;
  orderId?: string;
  paymentMethod?: string;
  totalItem?: number;
  totalTrxFeeAmount?: number;
  totalAmount?: number;
  totalPaymentFee?: number;
  referral?: string;
  isAutoDebet?: boolean;
  message?: string;
  tag?: string;
  client?: string;
  ifua?: string;
  gerai?: number;
  submittedAt?: Date | null;
  submittedBy?: string;
  internallyApprovedAt?: Date | null;
  internallyApprovedBy?: string;
  reviewedAt?: Date | null;
  reviewedBy?: string;
  status?: OrderStatus;
  batchId?: number;
  orderItems?: IOrderItem[];
  investorName?: string;
  investorIdentityNo?: string;
  investorCif?: string;
  investorEmail?: string;
  bankAccountTheBank?: number;
  bankAccountBranch?: string;
  bankAccountCurrency?: string;
  bankAccountNo?: string;
  bankAccountName?: string;
  bankName?: string;
  amountUniqueNumber?: number;
  transferStatus?: string;
  transferPath?: string;
  isPwm?: boolean;
  sameTimeOrder?: boolean;
  sameTimeOrderReference?: string;
  trxStatus?: string;
}

// YANG WAJIB DI ISI SUB
export const defaultValue: Readonly<ITrxOrder> = {
  id: 0,
  gerai: 1,
  isPwm: true
};
