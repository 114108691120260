import { ClientType } from "./enum.model";


export interface IClient {
  accountNo?: string;
  cif?: string;
  clientType?: ClientType;
  createYPStatus?: boolean;
  createdAt?: Date | null;
  createdBy?: string;
  id?: number;
  individuId?: string;
  institutionId?: string;
  sid?: string;
}

export const defaultValue: Readonly<IClient> = {
  accountNo: '',
  cif: '',
  id: 0
};
