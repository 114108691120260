export interface IMutualFund {
  buyable?: boolean;
  buyableCorp?: boolean;
  code?: string;
  currency?: string;
  custodianId?: number;
  cutoffTime?: string;
  factsheetPath?: string;
  feePercentTier?: number;
  firstMonth?: string;
  goodfundCutoffTime?: string;
  id?: number;
  investmentGoals?: string;
  investmentManagerId?: number;
  isActive?: boolean;
  isSameDaySubsRedeemAllowed?: boolean;
  isSyariah?: boolean;
  isTier?: boolean;
  isToChart?: boolean;
  launchDate?: Date | null;
  maxRedemptionFeePWM?: number;
  maxSubscriptionFeePWM?: number;
  maxSwitchingFeePWM?: number;
  mfAccNameRed?: string;
  mfAccNameRedSubs?: string;
  mfAccNameSubs?: string;
  mfAccNoRed?: string;
  mfAccNoRedSubs?: string;
  mfAccNoSubs?: string;
  mfBankRed?: string;
  mfBankRedSubs?: string;
  mfBankSubs?: string;
  mgtFee?: number;
  mgtFeeSa?: number;
  minBalance?: number;
  minBalanceSwitching?: number;
  minBalanceUnit?: number;
  minBalanceUnitSwitching?: number;
  minInitialSubscription?: number;
  minRedemption?: number;
  minRedemptionFeePWM?: number;
  minRedemptionUnit?: number;
  minSubscription?: number;
  minSubscriptionFeePWM?: number;
  minSwitching?: number;
  minSwitchingFeePWM?: number;
  minSwitchingUnit?: number;
  name?: string;
  productType?: string;
  prospectusPath?: string;
  rating?: number;
  ratingUpdatedAt?: Date | null;
  redNominal?: boolean;
  redUnit?: boolean;
  redemptionFee?: number;
  redemptionPWMFee?: number;
  redemptionTime?: string;
  redemptionUnitFee?: number;
  sInvestCode?: string;
  subscriptionFee?: number;
  subscriptionPWMFee?: number;
  subscriptionUnitFee?: number;
  switchingFee?: number;
  switchingPWMFee?: number;
  switchingTime?: string;
  switchingTo?: string;
  switchingUnitFee?: number;
  totalManagedFund?: number;
  updatedAt?: Date | null;
}

export const defaultValue: Readonly<IMutualFund> = {
  isSyariah: false,
  isTier: false,
  isSameDaySubsRedeemAllowed: false,
  isActive: false,
  isToChart: false,
  redNominal: false,
  redUnit: false,
  id: 0
};
