import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

import { IRMList } from 'app/shared/model/rm.model';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  rMs: [] as ReadonlyArray<IRMList>,
  totalItems: 0,
};

const apiUrl = 'services/clientservice/api/v1/rm';

// Async Actions

export const getRMs = createAsyncThunk('rm/fetch_rms', async ({ page, size, sort }: IQueryParams) => {
  // const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}` : ''}`;
  return axios.get<IRMList[]>(requestUrl);
});

export type RMState = Readonly<typeof initialState>;

export const RMSlice = createSlice({
  name: 'rm',
  initialState: initialState as RMState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getRMs), (state, action) => {
        state.loading = false;
        state.rMs = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isPending(getRMs), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getRMs), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = RMSlice.actions;

// Reducer
export default RMSlice.reducer;
