import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

import { ICustomerList } from 'app/shared/model/customer.model';
import { defaultValue, IInvestor } from 'app/shared/model/investor.model';
import { IQueryParams, serializeAxiosError, serializeAxiosErrorTitle } from 'app/shared/reducers/reducer.utils';
import { AppThunk } from 'app/config/store';
import { defaultValue as defaultValueRdn, IAccountBalance } from 'app/shared/model/account-balance.model';
import { ITransaction, ITransactionSearch, defaultValueSearchTransaction, defaultValue as defaultValueTransaction } from 'app/shared/model/transaction.model';

const initialState = {
  loading: false,
  loadingTransaction: false,
  errorMessage: null,
  successMessage: null,
  trxReviewOpen: false,
  trxSuccess: false,
  trxFailure: false,
  customers: [] as ReadonlyArray<ICustomerList>,
  customer: defaultValue,
  customerRdn: defaultValueRdn,
  customerPortfolio: [] as ReadonlyArray<ICustomerList>,
  customerTransaction: [] as ReadonlyArray<ITransaction>,
  totalItems: 0,
  totalItemsPortfolio: 0,
  totalItemsTransaction: 0,
  trxReview: defaultValueTransaction,
  trxSubmit: defaultValueTransaction,
  search: defaultValueSearchTransaction
};

const apiUrl = 'services/clientservice/api/v1/customers';
const apiUrlEntitiy = 'services/clientservice/api/v1/customer';
const apiUrlRdn = 'services/mftransactionservice/api/v1/transaction/account-balance/pwm';
const apiUrlPortfolio = 'services/mftransactionservice/api/v1/portfolio/all/pwm';
const apiUrlTrxReview = 'services/mftransactionservice/api/v2/transaction/order-preview/pwm';
const apiUrlTrxSubmit = 'services/mftransactionservice/api/v2/transaction/anytime/pwm';
const apiUrlTransaction = 'services/mftransactionservice/api/v1/transaction/filter/pwm';

// Async Actions

export const getCustomers = createAsyncThunk('customer/fetch_customers', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}` : ''}`;
  return axios.get<ICustomerList[]>(requestUrl);
});

export const getCustomersByRM = createAsyncThunk('customer/fetch_customer_by_rm', async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrlEntitiy}/${query}${sort ? `?page=${page}&size=${size}` : ''}`;
  return axios.get<ICustomerList[]>(requestUrl);
});

export const getCustomer: (cif: string) => AppThunk = cif => dispatch => {
  dispatch(getInvestor(cif));
  dispatch(getRdnBalance(cif));
};

export const getInvestor = createAsyncThunk('customer/fetch_customer_detail', async (cif :string) => {
  return axios.get<IInvestor>(`${apiUrlEntitiy}/detail/${cif}`);
});

export const getRdnBalance = createAsyncThunk('customer/fetch_customer_rdn', async (cif :string) => {
  return axios.get<IAccountBalance>(`${apiUrlRdn}/${cif}`);
});

export const getCustomersPortfolio = createAsyncThunk('customer/fetch_customer_portfolio', async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrlPortfolio}/${query}${sort ? `?page=${page}&size=${size}` : ''}`;
  return axios.get<ICustomerList[]>(requestUrl);
});

export const postReviewOrder = createAsyncThunk(
  'customer/review_order',
  async (trx: ITransaction) => axios.post<ITransaction>(`${apiUrlTrxReview}`, trx),
  { serializeError: serializeAxiosErrorTitle },
);

export const postSubmitOrder = createAsyncThunk(
  'customer/submit_order',
  async (trx: ITransaction) => axios.post<ITransaction>(`${apiUrlTrxSubmit}`, trx),
  { serializeError: serializeAxiosError },
);

export const getCustomersTransaction = createAsyncThunk('customer/fetch_customer_transaction',
  async (search :ITransactionSearch) => {
    // const filter = search.filter.implode
  // const requestUrl = `${apiUrlTransaction}?filter=in_process&filter=redemption&filter=uploaded&filter=completed
  // &page=0&size=10&sort=submittedAt,desc&cif=${cif}`;
  const requestUrl = `${apiUrlTransaction}?filter=${search.filter.join(",")}&page=${search.page}&size=${search.size}&sort=${search.sort}&cif=${search.cif}`;
  return axios.get<ITransaction[]>(requestUrl);
});

export const cancelOrder = ()  => dispatch => {
  dispatch(cancelOrderReview());
};

export const closeTrxReview = ()  => dispatch => {
  dispatch(trxReviewOpenReset());
};

export const resetTrxSuccess = ()  => dispatch => {
  dispatch(trxSuccessReset());
};

export const updateSearchParameters = (search: ITransactionSearch)  => dispatch => {
  dispatch(searchChange(search));
};

export type CustomerState = Readonly<typeof initialState>;

export const CustomerSlice = createSlice({
  name: 'customer',
  initialState: initialState as CustomerState,
  reducers: {
    reset() {
      return initialState;
    },
    searchChange(state, action) {
      return {
        ...state,
        search: action.payload,
      };
    },
    cancelOrderReview(state) {
      return {
        ...state,
        trxReview: defaultValueTransaction,
        trxReviewOpen: false,
      };
    },
    trxReviewOpenReset(state) {
      return {
        ...state,
        trxReviewOpen: false,
      };
    },
    trxSuccessReset(state) {
      return {
        ...state,
        trxSuccess: false,
        trxSubmit: defaultValueTransaction
      };
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(postReviewOrder, postSubmitOrder), state => {
        state.errorMessage = null;
        state.trxReviewOpen = false;
        state.trxSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(postReviewOrder, postSubmitOrder), (state, action) => {
        state.loading = false;
        state.trxReviewOpen = false;
        state.trxSuccess = false;
        state.trxFailure = true;
        // state.errorMessage = 'customer.messages.error';
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(postReviewOrder), (state, action) => {
        state.loading = false;
        state.trxReviewOpen = true;
        state.trxFailure = false;
        state.trxReview = action.payload.data;
        state.successMessage = 'customer.messages.success';
      })
      .addMatcher(isFulfilled(postSubmitOrder), (state, action) => {
        state.loading = false;
        state.trxSuccess = true;
        state.trxFailure = false;
        state.trxReview = defaultValueTransaction;
        state.trxSubmit = action.payload.data;
        state.successMessage = 'customer.messages.success';
      })
      .addMatcher(isFulfilled(getCustomers, getCustomersByRM), (state, action) => {
        state.loading = false;
        state.customers = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getCustomersPortfolio), (state, action) => {
        state.loading = false;
        state.customerPortfolio = action.payload.data;
        state.totalItemsPortfolio = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getCustomersTransaction), (state, action) => {
        state.loadingTransaction = false;
        state.customerTransaction = action.payload.data;
        state.totalItemsTransaction = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isPending(getCustomersTransaction), state => {
        state.customerTransaction = [];
        state.errorMessage = null;
        state.loadingTransaction = true;
      })
      .addMatcher(isRejected(getCustomersTransaction), (state, action) => {
        state.customerTransaction = [];
        state.loadingTransaction = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getInvestor), (state, action) => {
        state.loading = false;
        state.customer = action.payload.data;
      })
      .addMatcher(isFulfilled(getRdnBalance), (state, action) => {
        state.loading = false;
        state.customerRdn = action.payload.data;
      })
      .addMatcher(isPending(getInvestor), state => {
        state.customer = defaultValue;
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isPending(getRdnBalance), state => {
        state.customerRdn = defaultValueRdn;
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isPending(getCustomers, getCustomersByRM, getCustomersPortfolio), state => {
        state.customer = defaultValue;
        state.customerRdn = defaultValueRdn;
        state.customerTransaction = [];
        state.customerPortfolio = [];
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getCustomers, getCustomersByRM, getInvestor, getRdnBalance, getCustomersPortfolio), (state, action) => {
        state.customerTransaction = [];
        state.customerPortfolio = [];
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset, searchChange, cancelOrderReview, trxReviewOpenReset, trxSuccessReset } = CustomerSlice.actions;

// Reducer
export default CustomerSlice.reducer;
