export interface IMutualFundNAV {
  createdAt?: Date | null;
  createdBy?: string;
  effectiveDate?: Date | null;
  id?: number;
  mutualFundCode?: string;
  mutualFundId?: number;
  status?: string;
  updatedAt?: Date | null;
  updatedBy?: string;
  value?: number;
}

export const defaultValue: Readonly<IMutualFundNAV> = {};
