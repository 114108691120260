import { IoLogOutOutline } from "react-icons/io5";
import { MdOutlineTranslate } from "react-icons/md";
import { TiThMenu } from "react-icons/ti";
import { CardHeader, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { languages, locales } from 'app/config/translation';
import { setLocale } from 'app/shared/reducers/locale';
import * as React from 'react';
import { Storage } from "react-jhipster";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { logout } from 'app/shared/reducers/authentication';

interface HeaderProps {
  onDrawerToggle: () => void;
}

export const Header = (props: HeaderProps) => {
  const dispatch = useAppDispatch();
  // const history: any = useHistory();
  const navigate = useNavigate();
  const { onDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  function handleLogout() {
    Swal.fire({
      title: "Are you sure to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        // logout()
        // history.replace('/login')
        navigate('/logout');
      }
    });
  }
  
  // const handleLocaleChange = event => {
  //   const langKey = event.target.value;
  //   Storage.session.set('locale', langKey);
  //   dispatch(setLocale(langKey));
  // };

  const handleLocaleChange = langKey => {
    // eslint-disable-next-line no-console
    console.log(langKey);
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    setAnchorEl2(null);
  };

  const account = useAppSelector(state => state.authentication.account);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  return (
    <React.Fragment>
      <AppBar sx={{ backgroundColor: '#f5f5f5' }} position="sticky" elevation={1} className='py-2'>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <TiThMenu />
              </IconButton>
            </Grid>
            <Grid item xs />
            {/* <Grid item>
              {props.account.name ? `${props.account.name} - ${props.account.nik}` : 'Nama'}
            </Grid> */}
            {/* <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item>
              <IconButton
                color="primary"
                onClick={handleClickLang}
                size="small"
                aria-controls={open2 ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
              >
                <MdOutlineTranslate />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar aria-label="recipe">
                  {account.name ? account.name.charAt(0) : 'M'}
                </Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl2}
        id="account-menu"
        open={open2}
        onClose={handleClose2}
        onClick={handleClose2}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {locales.map(locale => (
          <MenuItem key={locale} onClick={() => handleLocaleChange(locale)}>
            {languages[locale].name}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              {account.name ? account.name.charAt(0) : 'M'}
            </Avatar>
          }
          title={account.name ? `${account.name}` : 'Nama'}
          subheader={account.nik ? `${account.nik}` : 'NIK'}
        />
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <IoLogOutOutline />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Header;