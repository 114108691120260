import { AssetInformationType, CompanyCharacteristic, CompanyType, Fatca, InstitutionIncomeLevel, InstitutionSourceOfFund, InvestmentObjective, InvestorRiskProfile, ProfitInformationType, StatementType } from "./enum.model";

export interface IInstitution {
  address?: string;
  address2?: string;
  aoaNo?: string;
  assetInfoPast3YrsIDRLastYr?: AssetInformationType;
  assetInfoPast3YrsIDR2YrsAgo?: AssetInformationType;
  assetInfoPast3YrsIDR3YrsAgo?: AssetInformationType;
  companyCharacteristic?: CompanyCharacteristic;
  companyCharacteristicOther?: string;
  companyDeedPath?: string;
  companyType?: CompanyType;
  country?: number;
  countryName?: string;
  countryEstablished?: number;
  countryEstablishedName?: string;
  district?: number;
  districtName?: string;
  eStablishmentDate?: Date | null;
  email?: string;
  fatca?: Fatca;
  faxNumber?: string;
  financialReport?: string;
  id?: number;
  incomeLevel?: InstitutionIncomeLevel;
  investmentObjective?: InvestmentObjective;
  investmentObjectiveOther?: string;
  lastArticlesOfAssociation?: string;
  lastaOAFilePath?: string;
  name?: string;
  npwp?: string;
  npwpDirector?: string;
  npwpDirectorRegDate?: Date | null;
  npwpDirectorFilePath?: string;
  npwpFilePath?: string;
  npwpRegDate?: Date | null;
  phoneNumber?: string;
  placeEstablished?: number;
  postalCode?: string;
  profitInfoPast3YrsIDRLastYr?: ProfitInformationType;
  profitInfoPast3YrsIDR2YrsAgo?: ProfitInformationType;
  profitInfoPast3YrsIDR3YrsAgo?: ProfitInformationType;
  province?: number;
  provinceName?: string;
  regency?: number;
  regencyName?: string;
  riskProfile?: InvestorRiskProfile;
  sIUPExpDate?: Date | null;
  sIUPFilePath?: string;
  sIUPNo?: string;
  sKDExpiry?: Date | null;
  sKDFilePath?: string;
  sKDNo?: string;
  sourceFundOther?: string;
  sourceOfFund?: InstitutionSourceOfFund;
  specimenFilePath?: string;
  statementType?: StatementType;
  userIdHots?: string;
  village?: number;
  villageName?: string;
}

export const defaultValue: Readonly<IInstitution> = {
  name: 'NAVI'
};
