import { EducationType, Gender, IdentityType, IncomeLevel, InvestmentObjective, InvestorRiskProfile, MaritalStatus, Occupation, Religion, SourceOfFund, StatementType } from "./enum.model";

export interface IIndividu {
    address?: string;
    birthDate?: Date | null;
    birthPlace?: string;
    country?: number;
    countryOfBirth?: string;
    domicileAddress?: string;
    domicileRegency?: number;
    domicileProvince?: number;
    domicilePostalCode?: string;
    domicileCountry?: number;
    education?: EducationType;
    educationOther?: string;
    gender?: Gender;
    homeOwnership?: string;
    id?: number;
    identityExpDate?: Date | null;
    identityFilePath?: string;
    identityNo?: string;
    identityType?: IdentityType;
    income?: IncomeLevel;
    investmentObjective?: InvestmentObjective;
    investmentObjectiveOther?: string;
    isIdValidForLifetime?: boolean;
    livenessScore?: string;
    mailingAddress?: string;
    maritalStatus?: MaritalStatus;
    mobileNumber?: string;
    motherMaidenName?: string;
    name?: string;
    nationality?: string;
    npwp?: string;
    npwpRegDate?: Date | null;
    occupation?: Occupation;
    occupationOther?: string;
    postalCode?: string;
    province?: number;
    regency?: number;
    religion?: Religion;
    religionOther?: string;
    riskProfile?: InvestorRiskProfile;
    rtrw?: string;
    selfieFilePath?: string;
    signatureFilePath?: string;
    signatureFileUrl?: string;
    sourceFund?: SourceOfFund;
    sourceFundOther?: string;
    spouseName?: string;
    statementType?: StatementType;
}

export const defaultValue: Readonly<IIndividu> = {
  isIdValidForLifetime: false,
  name: 'NAVI',
  mobileNumber: '',
  identityNo: '',
};