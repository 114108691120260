import { IOrderItem } from 'app/shared/model/order-item.model';
import { ITrxOrder, defaultValue as defaultValueTrxOrder } from 'app/shared/model/trx-order.model';

export interface ITransaction {
  trxOrderEntity?: ITrxOrder;
  orderItemEntities?: IOrderItem[];
  isAutoSubs?: boolean;
  asDate?: Date | null;
  token?: string;
  transactionType?: string;
  paymentStatus?: string;
  pendingMessage?: string;
  osName?: string;
  clientIp?: string;
}

export const defaultValue: Readonly<ITransaction> = {
  trxOrderEntity: defaultValueTrxOrder,
  orderItemEntities: [{}],
  isAutoSubs: false,
  asDate: null,
  token: null,
  transactionType: null,
  paymentStatus: null,
  pendingMessage: null,
  osName: "WEB",
  clientIp: ""
};

export interface ITransactionSearch {
  filter: string[];
  page: number;
  size: number;
  sort: string;
  cif: string;
}

export const defaultValueSearchTransaction: Readonly<ITransactionSearch> = {
  // filter: ['subscription'],
  // filter: ['in_process', 'redemption', 'uploaded', 'completed'],
  filter: ['submitted', 'in_process', 'uploaded', 'completed'],
  page: 0,
  size: 10,
  sort: 'submittedAt,desc',
  cif: ''
};
