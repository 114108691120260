export interface IMutualFundReturn {
  code?: string;
  createdAt?: Date | null;
  dailyRtn?: number;
  effectiveDate?: Date | null;
  fiveYearsRtn?: number;
  id?: number;
  inceptionRtn?: number;
  mutualFundId?: number;
  oneMonthRtn?: number;
  oneYearRtn?: number;
  sixMonthsRtn?: number;
  tenYearsRtn?: number;
  threeMonthsRtn?: number;
  threeYearsRtn?: number;
  yearToDateRtn?: number;
}

export const defaultValue: Readonly<IMutualFundReturn> = {};
